import dynamic from 'next/dynamic';

import { ImageLoadFragment } from '@hultafors/eripiowear/types';

import {
  BackgroundColors,
  Content,
  GenericPlugStyled,
  Inner,
  Intro,
  Label,
  Name,
  Title,
} from './generic-plug.styled';
import LinkSvg from './link.svg';

const DatoImage = dynamic(
  () => import('@hultafors/shared/components').then((mod) => mod.DatoImage),
  { ssr: false },
);

export interface GenericPlugProps {
  title?: string | null;
  intro?: string | null;
  image?: ImageLoadFragment | null;
  label?: string | null;
  name?: string | null;
  url?: string | null;
  large?: boolean;
  sizes?: string;
  aspectRatio?: number;
}

export const GenericPlug: React.FC<GenericPlugProps> = ({
  url = '',
  image,
  name,
  intro = '',
  title = '',
  label,
  large,
  sizes = '100vw',
  aspectRatio = 3 / 4,
}) => {
  return (
    <GenericPlugStyled>
      <Inner href={url ?? ''}>
        {image?.url ? (
          <DatoImage image={image} sizes={sizes} aspectRatio={aspectRatio} />
        ) : (
          <BackgroundColors />
        )}
        <Content $inverted={!!image}>
          {label && <Label $inverted={!!image}>{label}</Label>}
          {name && <Name $inverted={!!image}>{name}</Name>}
          <Title $inverted={!!image} $large={large}>
            {title}
          </Title>
          {intro && (
            <Intro $inverted={!!image} $large={large}>
              {intro}
            </Intro>
          )}
          {url && (
            <LinkSvg
              width={39}
              height={18}
              aria-hidden={true}
              focusable={false}
            />
          )}
        </Content>
      </Inner>
    </GenericPlugStyled>
  );
};
